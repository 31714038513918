import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import { meta } from "../../content_option";
// import { dataportfolio, meta } from "../../content_option";
import gantt_photo from './gantt.JPG';
import music_photo from './music.jpg';
import paper_masters from './paper_masters.JPG';
import paper_plank1 from './paper_plank1.JPG';
import paper_plank2 from './paper_plank2.JPG';
import SimpleEqJuce from './SimpleEqJuce.JPG';

export const Portfolio = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Portfolio | {meta.title} </title>{" "}
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4"> Portfolio </h1>{" "}
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <div className="mb-5 po_items_ho">
          {/* {dataportfolio.map((data, i) => {
            return (
              <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p>{data.description}</p>
                  <a href={data.link}>view project</a>
                </div>
              </div>
            );
          })} */}
          <div key={1} className="po_item">
            <img src={gantt_photo} alt="gantt_photo" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"Full stack application with gantt graphic library from DHTMLX"}</p>
              <a href={"https://github.com/vtergolina/Model-Full-Stack/tree/main"}>view project</a>
            </div>
          </div>
          <div key={3} className="po_item">
            <img src={paper_masters} alt="paper_masters" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"Nanophysics computational study on the effect of dielectric discontinuity"}</p>
              <a href={"https://pubs.aip.org/aip/jcp/article-abstract/147/11/114103/1062995/Effect-of-dielectric-discontinuity-on-a-spherical?redirectedFrom=fulltext"}>view project</a>
            </div>
          </div>
          <div key={4} className="po_item">
            <img src={paper_plank1} alt="paper_plank1" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"CFD study of turbulence on phytoplankton populations"}</p>
              <a href={"https://journals.aps.org/pre/abstract/10.1103/PhysRevE.104.065106"}>view project</a>
            </div>
          </div>
          <div key={5} className="po_item">
            <img src={paper_plank2} alt="paper_plank2" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"Computational study cover of EPJ plus"}</p>
              <a href={"https://link.springer.com/article/10.1140/epjp/s13360-022-03586-7"}>view project</a>
            </div>
          </div>
          <div key={6} className="po_item">
            <img src={SimpleEqJuce} alt="SimpleEqJuce" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"C++ EQ plugin developed with JUCE"}</p>
              <a href={"https://github.com/vtergolina/SimpleEQ-Juce/tree/main"}>view project</a>
            </div>
          </div>
          <div key={2} className="po_item">
            <img src={music_photo} alt="music_photo" style={{ "max-height": "125%" }} />
            <div className="content">
              <p>{"Hear my music on soundcloud!"}</p>
              <a href={"https://soundcloud.com/user-16157396-882923178/sets/party-n"}>view project</a>
            </div>
          </div>
        </div>
      </Container>
    </HelmetProvider>
  );
};
