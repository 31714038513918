const logotext = "VINICIUS";
const meta = {
    title: "Vinicius Beltram Tergolina",
    description: "I’m Vinicius Beltram Tergolina, a scientist specialized in numerical modeling and a full stack developer, currently working in Toulouse for Airbus",
};

const introdata = {
    title: "I’m Vinicius Beltram Tergolina",
    animated: {
        first: "I am a passionate about coding projects",
        second: "I develop and code numerical simulations",
        third: "I develop industrial web apps",
    },
    description: "Hello, this is my personal website, it contains some of my professional and personal projects. I am "
        + "a scientist specialized in numerical modeling and a full stack development, currently working in Toulouse for Airbus."
        + " I am also a passionate climber (hence the photo) and musician.",
};

const dataabout = {
    title: "Who am I",
    aboutme: "I am brazilian scientist with a degree in theoretical physics, a masters in computational physics and a PhD in computational mechanical engineering. "
        + "I come from the south of Brazil where, in the past, italian immigrants built their lives. "
        + "As a result I have dual nationality, brazilian/italian, and I am proud not only of the brazilian culture but of the italian heritage left by my ancestors"
        + "I have always had a passion for computers and technology, this motivated me to manipulate computers from a very young age. "
        + "Curiosity about the nature of the world we live in led me to gain passion for physics and sciences, from astronomy to fluid mechanics and nanophysics. "
        + "My two other big passions are music and climbing, I am a proud amateur musician and producer. I try to climb every weekend outside to enjoy nature and clear my head.",
};
const worktimeline = [{
    jobtitle: " Full Stack Developer / ProductOwner",
    where: "Airbus",
    date: "2022 - current",
},
{
    jobtitle: "PhD Researcher",
    where: "University of Lille",
    date: "2018 - 2022",
},
{
    jobtitle: "Academic Teaching",
    where: "University of Lille",
    date: "2020 - 2021",
},
];

const skills = [{
    name: "Python",
    value: 90,
},
{
    name: "Pandas",
    value: 70,
},
{
    name: "SQL",
    value: 70,
},
{
    name: "Javascript",
    value: 65,
},
{
    name: "React",
    value: 65,
},
{
    name: "C/C++",
    value: 60,
},
{
    name: "AWS",
    value: 25,
},
];

const services = [{
    title: "Full Stack Web Apps",
    description: "I can help you create a full stack web app. I am experienced with react/js as a frontend, python/flask as a backend and postgreSQL databases."
        + " I can also help you deploy your application in a cloud environment such as Amazon AWS.",
},
{
    title: "Numerical Studies",
    description: "I can help you with numerical studies of dynamical systems such as particle systems. I am experienced"
        + " with multidisciplinary physical systems in fluid mediums through the resolution of non-linear partial differential equation."
        + " I have also experience with molecular dynamics simulations.",
},
{
    title: "Data Analysis",
    description: "I can help you with data reduction and analysis based on the scientific method. I am experienced with machine learning methods and statistics.",
},
];

// const dataportfolio = [{
//     img: "https://picsum.photos/400/?grayscale",
//     description: "The wisdom of life consists in the elimination of non-essentials.",
//     link: "#",
// },
// {
//     img: "https://picsum.photos/400/800/?grayscale",
//     description: "The wisdom of life consists in the elimination of non-essentials.",
//     link: "#",
// },
// ];

const contactConfig = {
    YOUR_EMAIL: "vtergolina@gmail.com",
    YOUR_FONE: "(+33) 0751597312",
    description: "I am always looking for new opportunities and partnerships, feel free to contact me. ",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/vtergolina",
    linkedin: "https://www.linkedin.com/in/vinicius-beltram-tergolina-90623b15b/",
};
export {
    meta,   
    dataabout,
    // dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};